const {
	VITE_AUTH_CLIENT_ID,
	VITE_AUTH_AUDIENCE,
	VITE_AUTH_DOMAIN,
	VITE_UPLOADCARE_KEY,
	VITE_XMC_SYSTEM_ID,
	VITE_UNSPLASH_KEY,
	VITE_GIPHY_KEY,
	VITE_TINYMCE_KEY,
} = import.meta.env;

export default {
	path_prefix: import.meta.env.DEV ? '' : '/design',
	logging: true,
	blob: 'https://forms-blob-{regionCode}.sitecorecloud.io/api/blobuploads/filter',
	blob_delete: 'https://forms-blob-{regionCode}.sitecorecloud.io/api/blobuploads/{id}',
	full_url_upload_url: 'https://forms-blob-{regionCode}.sitecorecloud.io/api/blobuploads/new/url',
	full_upload_url: 'https://forms-blob-{regionCode}.sitecorecloud.io/api/blobuploads/new/file',
	uploadCare: {
		uploadUrl: 'https://upload.uploadcare.com/',
		apiUrl: 'https://api.uploadcare.com/',
		publicKey: VITE_UPLOADCARE_KEY,
	},
	unsplash: {
		api: 'https://api.unsplash.com',
		publicKey: VITE_UNSPLASH_KEY,
		getPhotos: '/photos?page={page}&per_page=16',
		getPhotosSearch: '/search/photos?page={page}&per_page=16&query={search}',
	},
	giphy: {
		api: 'https://api.giphy.com/v1/gifs',
		apiKey: VITE_GIPHY_KEY,
		search: '/search?api_key={apiKey}&q={search}&limit=16&offset={page}',
		trending: '/trending?api_key={apiKey}&limit=16&offset={page}',
	},
	tinymce: {
		apiKey: VITE_TINYMCE_KEY,
	},
	metadata_service: {
		url: 'https://forms-metadata-{regionCode}.sitecorecloud.io/api',
		// url: 'http://forms-metadata-api.staging.internal.services.com/api', //make sure this ends with a '/'
		paths: {
			//use generateUrlWithPathParams() from helpers to generate urls
			CreateMetadataRequest: '/metadata',
			GetAllMetadataForUserRequest: '/metadata',
			GetAllMetadataForUserRequest_with_params: '/metadata/pagesize/{pagesize}/page/{page}',
			GetMetadataRequest: '/metadata/{key}/{metadataVersionId}',
			GetAllMetadataVersionsRequest: '/metadata/{key}/pagesize/{pagesize}/page/{page}',
			CreateMetadataVersionRequest: '/metadata/{key}',
			DeleteMetadataRequest: '/metadata',
			GetLatestMetadataRequest: '/metadata/{key}/latest',
			VersionRequest: '/version',
		},
	},
	base_url: 'https://forms.sitecorecloud.io',
	asset_url: 'https://forms.sitecorecloud.io/assets',
	imgix_prefix: 'https://forms-assets-production-{regionCode}.sitecorecloud.io',
	possible_social_follow_icons: [
		'facebook',
		'twitter',
		'instagram',
		'pinterest',
		'youtube',
		'linkedin',
		'tumblr',
		'soundcloud',
		'tiktok',
		'snapchat',
		'custom',
	],
	deprecated_social: ['googleplus'],
	responsive_email_clients: [
		'iOS (iPhone/iPad)',
		'Android 4.x native client',
		'Android Outlook.com app',
		'Windows Phone 7.5',
		'BlackBerry OS 6',
		'BlackBerry OS 7',
		'BlackBerry Z10',
		'Kindle Fire native client',
	],
	content_custom_font_clients: ['Apple Mail', 'iOS Mail', 'Samsung Mail (Android 8.0)', 'Outlook for Mac'],
	social_follow_style_folder_names: [
		'default',
		'blue',
		'grey-circle',
		'grey-round-thin',
		'mint-round-thin',
		'peach-circle',
		'white-circle',
		'white-half-circle',
		'white-half-roundsquare',
		'white-half-solid',
		'white-roundsquare',
		'white-solid',
		'white-square',
		'anthracite-square',
		'black-half-round',
		'black-half-roundsquare',
		'black-half-solid',
		'black-round',
		'black-roundsquare',
		'black-solid',
		'black-square',
	],
	pusher_key: '4e264ca3e810cf2b1f57',
	google_fonts_key: 'AIzaSyAa3Cogdvn8yu9Cpf75KCGOXjBQrilGe1M',
	google_fonts_api: 'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=',
	///transparent_pixel_url: 'https://moosendclientdata.s3.amazonaws.com/yannis_150612184221417.png',
	default_autosave_timer: 30000,
	autosave_count: 1,
	autosave_enabled: true,
	undo_steps: 20,
	draft_limit: 50,
	debounce_times: {
		general: 300,
		transfer: 1,
		preview_template: 300,
		choose_template: 300,
		favourite_template: 300,
	},

	template_market: {
		market_default_category_name: 'Custom',
		market_pagesize: 18,
		market_private_templates_limit: 200,
	},
	drafts: {
		drafts_pagesize: 1000,
	},

	slot_spacing_side: 0,
	slot_spacing_center: 0,

	accepted_image_formats: ['.jpg', '.jpeg', '.png', '.gif'],

	xmc_apps: {
		base: 'https://xmapps-api.sitecorecloud.io/api/v1',
		getSites: '/sites',
		getCollections: '/collections',
	},

	entities_service: {
		base: 'https://forms-entities-{regionCode}.sitecorecloud.io/api',
		base_v2: 'https://forms-entities-{regionCode}.sitecorecloud.io/api/v2',
		createEntity: `/entities`,
		getAllBlueprints: `/entities/{entityId}/blueprints`,
		saveBlueprint: `/entities/blueprints`,
		updateBlueprint: `/entities/{entityId}/blueprints`,
		htmlToImage: '/entities/html-to-image',
		base_url: `/entities`,
		getAllExtras: `/entities/extras?Page={pagination}`,
		getAllWithFilters: `/entities/filter`,
		getEntity: `/entities/{entityId}/details`,
		duplicateEntity: `/entities/{entityId}/clone`,
		copyToEnvs: '/entities/copy-to-envs',
		deleteEntities: `/entities`,
		setTags: `/entities/{entityId}/tags`,
		setManyTags: `/entities/many/tags`,
		getTags: `/entities/all/tags?WhereEntityType={type}`,
		getDomains: `/entities/metadata/publish-domains`,
		getPublishPlugins: `/entities/metadata/{entityId}/ui-spec/publish/{entityType}/{type}?format=json`,
		getExtraFields: `/entities/metadata/{entityId}/criteria-spec/form/{type}?format=json`,
		getSettings: `/entities/{entityId}/settings`,
		setSettings: `/entities/{entityId}/settings`,
		updateEntity: `/entities/{entityId}`,
		publishEntity: `/entities/{entityId}/publish`,
		publishManyEntities: `/entities/many/publish`,
		unpublishEntity: `/entities/{entityId}/unpublish`,
		unpublishManyEntities: `/entities/many/unpublish`,
		entitiesAccess: '/entities/access',
		updateSites: '/entities/{entityId}/sites',
		archiveEntities: '/entities/archive',
		unarchiveEntities: '/entities/unarchive',
		getWebhooks: (page, orderBy, pageSize) => `/webhooks?Page=${page}&PageSize=${pageSize}&OrderBy=${orderBy}`,
		createWebhook: '/webhooks',
		updateWebhook: '/webhooks/{webhookId}',
		deleteWebhook: '/webhooks/{webhookId}',
		testWebhook: '/webhooks/{webhookId}/test',
		getWebhook: '/webhooks/{webhookId}',
		searchWebooks: '/webhooks/search',
		assignWebhook: '/entities/{entityId}/webhook/{webhookId}',
		reassignWebhook: '/entities/{entityId}/webhook/{webhookId}',
		unassignWebhook: '/entities/{entityId}/webhook/',
	},

	supported_browsers: {
		//the number is the version, if for example it is safaru: 9, it means safari 9+ is supported
		chrome: 0,
		firefox: 0,
		msedge: 13,
		safari: 9,
		opera: 30,
	},

	contentHub: {
		auth: '/integrations/content-hub/authenticate',
		search: '/integrations/content-hub/search',
	},
	authentication: {
		xmc_system_id: VITE_XMC_SYSTEM_ID,
		audience: VITE_AUTH_AUDIENCE,
		client_id: VITE_AUTH_CLIENT_ID,
		domain: VITE_AUTH_DOMAIN,
		appSwitcherEnv: 'production',
		pocketNavEnv: 'prod',
		portal_url: 'https://portal.sitecorecloud.io',
	},
	inventory: {
		base: 'https://platform-inventory.sitecorecloud.io',
		get_tenant: '/api/inventory/v1/tenants/{tenantId}',
	},
	xmc: {
		dashboard_url: 'https://xmapps.sitecorecloud.io/',
	},
	publisher: {
		baseUrl: (region) => `https://forms-publisher-${region || 'euw'}.sitecorecloud.io`,
		path: '/api/v1/forms',
		edgePath: '/v1/forms/publisher',
		preview: (id) => `/api/v1/forms/${id}/Preview`,
	},
};
