import axios from 'axios';
import config, { importedConfig } from '../../../config/config';

import { MetadataCategories, ApiAuthorisationModes } from '../resources';

import { generateUrlWithPathParams, getEditorType, makeApiGatewayCall } from '../helper';

import { buildMetadataServicePayload } from './metadataService';

export const postCustomRow = (title, row, id, order, AuthorisationMode) => {
	const editorType = getEditorType();

	const orderAttr = order ? { order } : {};

	const rowId = id ? id : row.id;

	let data = buildMetadataServicePayload(
		rowId,
		MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_STRUCTURES,
		{
			ContentType: 'application/json',
			Data: row,
		},
		{
			id: rowId,
			title,
			type: row.type,
			// row,
			...orderAttr,
		},
		AuthorisationMode,
	);

	let createMetadataUrl = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.CreateMetadataRequest);

	return makeApiGatewayCall(createMetadataUrl, 'post', data);
	// return new Promise((res, rej) => res('asd'));
};

export const getCustomRows = () => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_STRUCTURES,
			LatestOnly: true,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetAllMetadataForUserRequest);

	return makeApiGatewayCall(url, 'get', {}, {}, data);
};

export const getCustomRow = (rowMetadata) => {
	const editorType = getEditorType();

	let data = {
		params: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_STRUCTURES,
		},
	};

	let url = generateUrlWithPathParams(config.metadata_service.url, config.metadata_service.paths.GetLatestMetadataRequest, {
		key: rowMetadata.id,
		metadataVersionId: rowMetadata.metadataVersionId,
	});

	return makeApiGatewayCall(url, 'get', {}, {}, data);
};

export const deleteCustomRow = (key) => {
	const editorType = getEditorType();

	let data = {
		data: {
			Category: MetadataCategories[editorType].EDITOR_SAVED_CUSTOM_STRUCTURES,
			key,
		},
	};

	let url = config.metadata_service.url + config.metadata_service.paths.DeleteMetadataRequest;

	return makeApiGatewayCall(url, 'delete', {}, {}, data);
};

export const getCustomRowsFromRsp = (customRowsRsp) => {
	let customRows = customRowsRsp.data.Data.map((rowData) => {
		const orderAttr = rowData.Attributes.find((attr) => attr.Key === 'order');
		const order = orderAttr ? orderAttr.Value : '';

		const rowAttr = rowData.Attributes.find((attr) => attr.Key === 'row');
		const row = rowAttr ? JSON.parse(rowAttr.Value) : '';

		const typeAttr = rowData.Attributes.find((attr) => attr.Key === 'type');
		const type = typeAttr ? typeAttr.Value : '';

		return {
			// AuthorisationMode: rowData.AuthorisationMode,
			title: rowData.Attributes.find((attr) => attr.Key === 'title').Value,
			row,
			order,
			type,
			metadataVersionId: rowData.MetadataVersionId,
			id: rowData.Key,
			authorisationMode: rowData.AuthorisationMode,
		};
	});

	return customRows;
};
